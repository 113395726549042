import { getGeoIpDetails } from "sezzle-website/src/api/api";

export async function initializeGTM(gtmID) {
  if (!gtmID) {
    console.error('GTM ID is not set');
    return;
  }

  // Inject GTM script into the <head> if geolocation allows
  try {
    const response = await getGeoIpDetails();
    const countryCode = response?.data?.country_iso_code;
    if (countryCode !== 'CA') {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtmID);

      // Inject GTM noscript iframe into the <body>
      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmID}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      noscript.appendChild(iframe);

      // Append to <body>
      if (document.body) {
        document.body.appendChild(noscript);
      } else {
        console.error('document.body is not available');
      }
    }
  } catch (e) {
    console.error(e);
  }
}
